import React from "react";
import ReactDOM from "react-dom/client";
import "./CSS/Layout.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CookiesProvider } from "react-cookie";

const theme = createTheme({
  palette: {
    // use tools to pick color
    // https://material.io/resources/color/#!/?view.left=0&view.right=0
    primary: {
      main: "#223870",
      contrastText: "#fff",
      dark: "#101B38",
      light: "#497AF5",
    },
    secondary: {
      main: "#ED2D22",
      contrastText: "#fff",
      dark: "#9F2D22",
      light: "#EA7670",
    },
    dark: {
      main: "#585958",
      contrastText: "#fff",
      dark: "#2f302f",
      light: "#858685",
    },
    third: {
      main: "#00b0f0",
      contrastText: "#000",
      dark: "#333745",
      light: "#77D3E6",
    },
    forth: {
      main: "#F5F5F5",
      contrastText: "#223870",
      dark: "#a8a8a8",
      light: "#fff",
    },
    darkgray: {
      main: "#B8B8B8",
      contrastText: "#000",
      dark: "#707070",
      light: "#999999",
    },
    info: {
      main: "#f7c97e",
      contrastText: "#715011",
      dark: "#7e5700",
      light: "#fabc49",
    },
  },
  typography: {
    h2: {
      fontWeight: "bold",
      fontSize: 27,
      // color: '#4472c4',
      textAlign: "center",
      margin: "40px 0px",
    },
    h3: {
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "left",
      margin: "56px 0px 10px 0px",
      "@media (max-width:479px)": {
        fontSize: 15,
        fontWeight: "bold",
        textAlign: "left",
        margin: "56px 0px 10px 0px",
      },
    },
    h5: {
      fontSize: 18,
      textAlign: "center",
    },
    h6: {
      fontSize: 16,
      // textAlign: "center",
    },
    button: {
      textTransform: "none",
    },
    fontFamily: `Roboto`,
    fontSize: 14,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        component: "div",
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "filled" && {
            backgroundColor: "white",
            borderRadius: 4,
            "& .MuiFilledInput-input": {
              paddingTop: "21px",
              paddingBottom: "12px",
            },
          }),
        }),
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          "& .MuiTypography-root": {
            textAlign: "left",
            fontWeight: "bold",
          },
        },
        root: {
          paddingBottom: "0px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0px 16px",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "black",
          fontSize: 14,
          "&.Mui-selected": {
            borderColor: "#00b0f0",
            color: "black",
          },
        },
      },
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
