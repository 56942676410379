import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  ButtonGroup,
  Menu,
  MenuList,
  MenuItem,
  Divider,
  IconButton,
  Link,
  Slide,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import AdjustIcon from "@mui/icons-material/Adjust";
import MenuIcon from "@mui/icons-material/Menu";

import PersonIcon from "@mui/icons-material/Person";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "../CSS/HomeFrame.css";
import "../CSS/Layout.css";
import LogoBar from "../Assets/logo_bar.png";
import {
  GlobalContext,
  publicSites,
  unclickedMenu,
} from "../utils/GlobalContext";
import { Cookies } from "react-cookie";
import ReactGA from "react-ga4";
import { backendCall } from "../utils/Network";

export default function HomeFrame({ currentPageName }) {
  const {
    user,
    setUser,
    globalMessage,
    setGlobalMessage,
    currentMenu,
    setCurrentMenu,
    isMobile,
  } = useContext(GlobalContext);
  const [showGlobalMessage, setShowGlobalMessage] = useState(false);
  const [message, setMessage] = useState("");
  const cookies = new Cookies();
  const [disabled, setDisabled] = useState(false);
  const [menuSuccessfulAwardsAnchor, setMenuSuccessfulAwardsAnchor] =
    useState(null);
  const [menuCommunityAnchor, setMenuCommunityAnchor] = useState(null);
    const [ menuStateDashboardAnchor, setMenuStateDashboardAnchor] = useState(null);
  const [menuAccountAnchor, setMenuAccountAnchor] = useState(null);
  const [menuMobileAnchor, setMenuMobileAnchor] = useState(null);
  const [menuMobileCommunityAnchor, setMenuMobileCommunityAnchor] =
    useState(null);
  const [notification, setNotification] = useState([]);
  const [switchNotification, setSwitchNotification] = useState(true);
  const [currentNotification, setCurrentNotification] = useState();
  const [notificationCounter, setNotificationCounter] = useState(0);
  const [direction, setDirection] = useState("right");
  const containerRef = useRef(null);

  let redirectPath = useNavigate();
  useEffect(() => {
    document.title = currentPageName;

    const fetchNotification = async () => {
      const response = await backendCall.get("/non_expired_notification");
      setNotification(response.data);
    };

    fetchNotification();
  }, []);

  useEffect(() => {
    // Execute after notification is rendered
    if (notification.length !== 0) {
      setTimeout(() => {
        setDirection(
          "right",
          setSwitchNotification(
            true,
            setCurrentNotification(
              <Slide
                direction={direction}
                in={switchNotification}
                mountOnEnter
                unmountOnExit
                container={containerRef.current}
                timeout={{ enter: 400, exit: 400 }}
              >
                <Typography>
                  {notification[notificationCounter].message + " "}
                  <Link
                    href={notification[notificationCounter].link_url}
                    color="secondary"
                    target="_blank"
                    key={notification[notificationCounter].link_name}
                  >
                    {notification[notificationCounter].link_name}
                  </Link>
                  {"."}
                </Typography>
              </Slide>
            )
          )
        );
      }, 500);
    }
  }, [notification, switchNotification]);

  useEffect(() => {
    if (switchNotification === true) {
      setTimeout(() => {
        if (direction === "right") {
          setDirection("left", setSwitchNotification(false));
        }
        if (notificationCounter === notification.length - 1) {
          setNotificationCounter(0);
        } else {
          setNotificationCounter(notificationCounter + 1);
        }
      }, 10000);
    }
  }, [switchNotification]);

  const handleSignout = () => {
    cookies.remove("accessToken");
    cookies.remove("firstName");
    cookies.remove("lastName");
    cookies.remove("email");
    cookies.remove("phoneNumber");
    cookies.remove("organizationId");
    cookies.remove("title");
    cookies.remove("accountId");
    cookies.remove("organizationUserId");
    cookies.remove("organizationName");
    cookies.remove("isLogin");
    setUser({ isLogin: false });
    redirectPath("/login");
  };

  useEffect(() => {
    if (user.isLogin === true) {
      setDisabled(false);
    }
    if (cookies.get("isLogin") === undefined || user.isLogin === false) {
      setDisabled(true);
    }
  }, [user.isLogin]);

  useEffect(() => {
    if (!globalMessage.content) {
      setShowGlobalMessage(false);
    } else {
      setShowGlobalMessage(true);
      setMessage(globalMessage.content);
    }
  }, [globalMessage]);

  function highlightCurrentMenu(menuId) {
    let newMenu = Object.assign({}, unclickedMenu);
    if (menuId !== null) {
      newMenu[menuId] = "third";
    }
    setCurrentMenu(newMenu);
  }

  function OrganizationMenuList() {
    if (user.organizationId) {
      const menus = [];
      if (isMobile === false) {
        menus.push(<Divider key={0} />);
      }
      menus.push(
        <MenuItem
          key={1}
          onClick={() => {
            setMenuAccountAnchor(null);
            redirectPath("/organization");
            highlightCurrentMenu(null);
          }}
        >
          My Organization
        </MenuItem>
      );
      menus.push(
        <MenuItem
          key={2}
          onClick={() => {
            setMenuAccountAnchor(null);
            redirectPath("/users");
            highlightCurrentMenu(null);
          }}
        >
          Users
        </MenuItem>
      );
      menus.push(
        <MenuItem
          key={3}
          onClick={() => {
            setMenuAccountAnchor(null);
            redirectPath("/projects");
            highlightCurrentMenu(null);
          }}
        >
          Projects
        </MenuItem>
      );
      return menus;
    }
    return <></>;
  }

  return (
    <>
      <Box className="header-bar">
        <Box className="flex-row-left">
          <Box
            component="img"
            className="logo"
            src={LogoBar}
            onClick={() => {
              highlightCurrentMenu(null);
              redirectPath("/");
            }}
          />
        </Box>

        {isMobile && (
          <Box display="flex" align-item="center">
            <IconButton
              sx={{ color: "white" }}
              onClick={(event) => {
                setMenuMobileAnchor(event.currentTarget);
              }}
            >
              <MenuIcon sx={{ mr: 1 }} />
            </IconButton>
          </Box>
        )}

        {!isMobile && (
          <Box className="flex-row-left">
            <ButtonGroup variant="text">
              <Button
                title="Funding Opportunities"
                color={currentMenu[1]}
                onClick={() => {
                  redirectPath("/nofo");
                  highlightCurrentMenu(1);
                }}
              >
                Funding Opportunities
              </Button>
              <Button
                title="Plan Ahead"
                color={currentMenu[2]}
                onClick={() => {
                  redirectPath("/planahead");
                  highlightCurrentMenu(2);
                }}
              >
                Plan Ahead
              </Button>
              <Button
                title="Successful Awards"
                color={currentMenu[3]}
                onClick={() => {
                  setMenuSuccessfulAwardsAnchor(null);
                  redirectPath("/successfulawards");
                  highlightCurrentMenu(3);
                }}
              >
                Successful Awards
              </Button>



              {/*<Button*/}
              {/*  title="State Dashboard"*/}
              {/*  color={currentMenu[4]}*/}
              {/*  onClick={() => {*/}
              {/*    setMenuStateDashboardAnchor(null);*/}
              {/*    redirectPath("/statedashboard");*/}
              {/*    highlightCurrentMenu(4);*/}
              {/*  }}*/}
              {/*>*/}
              {/*  State Dashboard*/}
              {/*</Button>*/}




              {/*<Button*/}
              {/*  title="Community Forum"*/}
              {/*  color={currentMenu[5]}*/}
              {/*  onClick={(event) => {*/}
              {/*    setMenuCommunityAnchor(event.currentTarget);*/}
              {/*  }}*/}
              {/*  endIcon={<ArrowDropDownIcon />}*/}
              {/*>*/}
              {/*  Community Forum*/}
              {/*</Button>*/}
              <Button
                title="Get Help"
                color={currentMenu[5]}
                onClick={() => {
                  redirectPath("/gethelp");
                  highlightCurrentMenu(5);
                }}
              >
                Get Help
              </Button>
            </ButtonGroup>
            <Box sx={{ ml: "20px" }} className="flex-row-left">
              {!user.isLogin && !sessionStorage.getItem('isAdminLoggedIn') && (
                <Button
                  title="Login"
                  variant="outlined"
                  color="forth"
                  onClick={() => {
                    redirectPath("/login");
                    highlightCurrentMenu(null);
                  }}
                >
                  Login
                </Button>
              )}
              {user.isLogin && (
                <>
                  <Button
                    title="user"
                    color="forth"
                    onClick={(event) => {
                      setMenuAccountAnchor(event.currentTarget);
                    }}
                    startIcon={<PersonIcon />}
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {currentPageName === "Landing Page" && (
        <Box className="notification" ref={containerRef}>
          {currentNotification}
        </Box>
      )}

      {currentPageName !== "Landing Page" && (
        <Box className="title-section">
          <AdjustIcon className="left-icon" />
          {currentPageName}
        </Box>
      )}

      <Menu
        anchorEl={menuMobileAnchor}
        open={menuMobileAnchor !== null}
        onClose={() => {
          setMenuMobileAnchor(null);
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              setMenuCommunityAnchor(null);
              redirectPath("/nofo");
              highlightCurrentMenu(1);
            }}
          >
            Funding Opportunities
          </MenuItem>
          <MenuItem
            onClick={() => {
              setMenuCommunityAnchor(null);
              redirectPath("/planahead");
              highlightCurrentMenu(2);
            }}
          >
            Plan Ahead
          </MenuItem>
          <MenuItem
            onClick={() => {
              setMenuCommunityAnchor(null);
              redirectPath("/successfulawards");
              highlightCurrentMenu(3);
            }}
          >
            Successful Awards
          </MenuItem>

            {/*<MenuItem*/}
            {/*    onClick={() => {*/}
            {/*        setMenuCommunityAnchor(null);*/}
            {/*        redirectPath("/statedashboard");*/}
            {/*        highlightCurrentMenu(4);*/}
            {/*    }}*/}
            {/*>*/}
            {/*    State Dashboard*/}
            {/*</MenuItem>*/}

          {/*<MenuItem*/}
          {/*  onClick={(event) => {*/}
          {/*    setMenuMobileCommunityAnchor(event.currentTarget);*/}
          {/*  }}*/}
          {/*  // endIcon={<ArrowDropDownIcon size="small" />}*/}
          {/*>*/}
          {/*  Community Forum*/}
          {/*</MenuItem>*/}
          <MenuItem
            onClick={() => {
              setMenuCommunityAnchor(null);
              redirectPath("/gethelp");
              highlightCurrentMenu(6);
            }}
          >
            Get Help
          </MenuItem>
          <Divider />
          {!user.isLogin && (
            <MenuItem
              onClick={() => {
                setMenuCommunityAnchor(null);
                redirectPath("/login");
                highlightCurrentMenu(null);
              }}
            >
              Login
            </MenuItem>
          )}
          {user.isLogin && (
            <MenuItem
              onClick={() => {
                setMenuAccountAnchor(null);
                redirectPath("/account");
                highlightCurrentMenu(null);
              }}
            >
              Account
            </MenuItem>
          )}
          {OrganizationMenuList()}
          {user.isLogin && <Divider />}
          {user.isLogin && (
            <MenuItem
              onClick={() => {
                handleSignout();
                highlightCurrentMenu(null);
              }}
            >
              Sign Out
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      <Menu
        anchorEl={menuCommunityAnchor}
        open={menuCommunityAnchor !== null}
        onClose={() => {
          setMenuCommunityAnchor(null);
        }}
      >
        <MenuList dense>
          <MenuItem
            onClick={() => {
              setMenuCommunityAnchor(null);
              redirectPath("/boardmessages");
              highlightCurrentMenu(4);
            }}
          >
            Board Messages
          </MenuItem>
          <MenuItem
            disabled={disabled}
            onClick={() => {
              setMenuCommunityAnchor(null);
              redirectPath("/requests");
              highlightCurrentMenu(4);
            }}
          >
            Requests
          </MenuItem>
        </MenuList>
      </Menu>

      <Menu
        anchorEl={menuMobileCommunityAnchor}
        open={menuMobileCommunityAnchor !== null}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setMenuMobileCommunityAnchor(null);
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              setMenuMobileCommunityAnchor(null);
              redirectPath("/boardmessages");
              highlightCurrentMenu(4);
            }}
          >
            Board Messages
          </MenuItem>
          <MenuItem
            disabled={disabled}
            onClick={() => {
              setMenuCommunityAnchor(null);
              redirectPath("/requests");
              highlightCurrentMenu(5);
            }}
          >
            Requests
          </MenuItem>
        </MenuList>
      </Menu>

      <Menu
        anchorEl={menuAccountAnchor}
        open={menuAccountAnchor !== null}
        onClose={() => {
          setMenuAccountAnchor(null);
        }}
      >
        <MenuList dense>
          <MenuItem
            onClick={() => {
              setMenuAccountAnchor(null);
              redirectPath("/account");
              highlightCurrentMenu(null);
            }}
          >
            Account
          </MenuItem>
          {OrganizationMenuList()}
          <Divider />
          <MenuItem
            onClick={() => {
              handleSignout();
              highlightCurrentMenu(null);
            }}
          >
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
      <Snackbar
        open={showGlobalMessage}
        // autoHideDuration={3000}
        onClose={() => {
          setGlobalMessage({ ...globalMessage, content: null });
        }}
        sx={{ marginRight: "20px" }}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setGlobalMessage({ ...globalMessage, content: null });
          }}
          severity={globalMessage.severity}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
