import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import HomeFrame from "../Components/HomeFrame";
import Divider from "@mui/material/Divider";
import Footer from "../Components/Footer";
import "../CSS/GetHelp.css";
import { redirect } from "react-router-dom";

export default function GetHelp() {
  let redirectPath = useNavigate();
  return (
    <>
      <HomeFrame currentPageName="Get Help" />
      <Box className="content-body">
        <Typography>
          The{" "}
          <strong>
            <a
              href="https://bac.umd.edu/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Build America Center (BAC)
            </a>{" "}
          </strong>
          has supported public agencies for a variety of activities including
          grant applications, plan development, funding strategy, project
          delivery, and federal compliance. Contact us so we can help you start
          your funding application.
        </Typography>
        <Box className="button-box">
          <Button
            variant="contained"
            className="square-button"
            onClick={() => {
              redirectPath("/appointment");
              // window.open("mailto:bac@umd.edu", "_blank");
            }}
          >
            1-Click Technical Assistance
          </Button>
          <Button
            variant="contained"
            className="square-button"
            onClick={() => {
              window.open("https://bac.umd.edu/", "_blank");
            }}
          >
            Visit Us for Latest Updates
          </Button>
          <Button
            variant="contained"
            className="square-button"
            onClick={() => {
              window.open("https://bac.umd.edu/#SignUpForUpdates", "_blank");
            }}
          >
            Sign Up to BAC Newsletter
          </Button>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography>
            <a href="http://bac.umd.edu">Build America Center</a>
          </Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Typography> Email: bac@umd.edu</Typography>
        </Box>
        <Typography variant="h3" className="resource-title">
          Resources
        </Typography>
        <Typography variant="h5" align="left"></Typography>

        <Typography>
          The{" "}
          <strong>
            <a
              href="https://www.transportation.gov/dot-navigator"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              DOT Navigator
            </a>{" "}
          </strong>
          is a comprehensive resource for local public agencies to understand
          BIL grant application, plan and deliver infrastructure project
          services, as well as technician assistance resources.
        </Typography>
        <Typography>
          Visit DOT Navigator for BIL Opportunities:{" "}
          <a href="https://www.transportation.gov/dot-navigator">
            DOT Navigator | US Department of Transportation
          </a>
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography>
          <strong>
            <a
              href="https://www.transportation.gov/buildamerica/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              Build America Bureau
            </a>{" "}
          </strong>
          offers technical assistance to project sponsors during every stage of
          project development. Being considered as the US DOT's front door, the
          Bureau educates project sponsors about how sponsors can best combine
          DOT credit instruments, funding programs, and innovative project
          delivery approaches to put new transportation infrastructure into
          service for the state, county, and community.
        </Typography>
        <Typography mt="10px">
          The Bureau offers project-level technical assistance every step of the
          way, including helping an agency navigate the environmental review
          process. Thinking about a project? Reach out to the Bureau as early as
          possible in the process so the team can work with your agency on key
          requirements.
        </Typography>
        <Box display="flex">
          <Typography>Email: BuildAmerica@dot.gov</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Typography> Phone: 202-366-2300</Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Typography>
          <strong>
            <a
              href="https://www.fhwa.dot.gov/clas/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              The Center for Local Aid Support (CLAS)
            </a>{" "}
          </strong>
          connects with Local, Tribal and Federal Land Management Agency
          transportation networks to provide national leadership in the
          advancement of innovative technologies and practices. The Center is
          focused on workforce development and promoting cutting-edge solutions
          to deliver real results that enhance our nation's transportation
          system.
        </Typography>
        <Typography align="left" sx={{ my: 2, ml: 10, fontWeight: "bold" }}>
          <a
            href="https://www.fhwa.dot.gov/clas/ltap/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "black" }}
          >
            Local Technical Assistance Program (LTAP)
          </a>
        </Typography>
        <Divider variant="inset" sx={{ my: 2 }} />
        <Typography sx={{ ml: 10 }}>
          There are 51 LTAP centers - one in each State and one serving Puerto
          Rico and the Virgin Islands. The centers provide surface
          transportation technical assistance and training to local public
          agencies (LPAs). The training and partnership provided by LTAP allow
          local and rural communities to maximize the performance of their
          transportation workforce, manage a safe, efficient, and
          environmentally sound transportation infrastructure, and integrate the
          deployment of innovations into daily operations.
        </Typography>
        <Typography align="left" sx={{ my: 2, ml: 10, fontWeight: "bold" }}>
          <a
            href="https://www.fhwa.dot.gov/clas/ttap/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "black" }}
          >
            Tribal Technical Assistance Program (TTAP)
          </a>
        </Typography>
        <Divider variant="inset" sx={{ my: 2 }} />
        <Typography sx={{ ml: 10 }}>
          The tribal road inventory includes approximately 58,500 miles of
          Bureau of Indian Affairs and tribally-owned public roads, 102,500
          miles of State, County, and local government public roads, and 12,500
          miles of proposed roads. TTAP delivers highway curriculum and
          technical assistance to the 567 federally-recognized tribes. These
          resources enable them to build workforce capacity and innovatively
          address roadway network challenges.
        </Typography>

        <Divider sx={{ my: 2 }} />
        <Typography>
          The{" "}
          <strong>
            <a
              href="https://www.fhwa.dot.gov/ipd/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              FHWA Center for Innovative Finance Support
            </a>
          </strong>
          , Office of Performance and Innovative Finance Support, provides a
          comprehensive set of tools and resources to assist the transportation
          community in exploring and implementing innovative strategies to
          deliver programs and projects. Innovation can help overcome resource
          constraints, address energy and environmental considerations, and
          promote efficiencies in program delivery.
        </Typography>
        <Typography mt="10px">
          Visit FHWA CIFS website for tools and resources or request for
          technical assistance:{" "}
          <a href="https://www.fhwa.dot.gov/ipd/">FHWA CIFS</a>
        </Typography>

        <Divider sx={{ my: 2 }} />
        <Typography sx={{ fontWeight: "bold" }}>
          <a
            href="https://www.transportation.gov/rural"
            target="_blank"
            rel="noreferrer"
            style={{ color: "black" }}
          >
            USDOT Rural Opportunities to Use Transportation for Economic Success
            (ROUTES)
          </a>
        </Typography>
        <Typography>
          Rural Opportunities to Use Transportation for Economic Success
          supports potential applicants in identifying and navigating USDOT
          discretionary grant funding opportunities for rural transportation
          projects.
        </Typography>
        <Box display="flex">
          <Typography>
            <a href="https://www.transportation.gov/rural">ROUTES Initiative</a>
          </Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Typography>Email: rural@dot.gov</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Typography> Phone: 202-366-4544</Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Typography>
          <strong>
            <a
              href="https://www.nationalrtap.org/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              National Rural Transit Assistance Program (National RTAP)
            </a>
          </strong>{" "}
          addresses the training and technical assistance needs of rural, and
          tribal transit operators across the nation, and supports the state
          RTAP programs. National RTAP provides a comprehensive set of free
          technical assistance programs and resources includes training
          materials, webinars, newsletters and technical briefs, peer resources,
          research, and innovative technology initiatives.
        </Typography>
        <Box display="flex">
          <Typography>Email: support@nationalrtap.org</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Typography> Phone: 888-589-6821</Typography>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
