import { useState, createContext, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import { useMediaQuery } from "@mui/material";
import * as React from "react";

export const digramThemes = [
  "#03a5e1",
  "#353534",
  "#f4932a",
  "#c2e812",
  "#5bc65b",
  "#ef5350",
  "#eeba8a",
  "#848d7a",
  "#426e93",
  "#4c6760",
];

export const GlobalContext = createContext(null);

export const publicSites = [
  "/signup",
  "/forgetpassword",
  "/resetpassword",
  "/login",
  "/",
  "/nofo",
  "/planahead",
  "/nofodetail",
  "/boardmessages",
  "/successfulawards",
  "/statedashboard",
  "/adminconsole",
  "/gethelp",
  "/disclaimer",
  "/projectdetail",
  "/checksimilarity",
  "/appointment",
  "/appointment",
  "/adminlogin"
];

export const unclickedMenu = {
  1: "forth",
  2: "forth",
  3: "forth",
  4: "forth",
  5: "forth",
};

export function GlobalWrapper({ children }) {
  const cookies = new Cookies();
  const [coo, setCookie] = useCookies(["lastAccess"]);
  let redirectPath = useNavigate();
  const [user, setUser] = useState({});
  let location = useLocation();
  const userSites = ["/signup", "/forgetpassword", "/resetpassword", "/login"];

  // severity: error, warning, info, success
  const [globalMessage, setGlobalMessage] = useState({
    severity: null,
    content: null,
  });

  const [currentMenu, setCurrentMenu] = useState(unclickedMenu);
  const isMobile = useMediaQuery("(max-width:479px)");

  const globals = {
    user,
    setUser,
    globalMessage,
    setGlobalMessage,
    currentMenu,
    setCurrentMenu,
    isMobile,
  };

  useEffect(() => {
    // remove parameters
    let currentURL = window.location.pathname.split("/");
    if (currentURL.length >= 1) {
      currentURL = "/" + currentURL[1];
    } else {
      currentURL = "/";
    }

    // Stores the last accessed site, when the user login, user will go back to the last accessed site
    if (!userSites.includes(currentURL)) {
      setCookie("lastAccess", window.location.pathname, {
        expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      });
    }

    // If cookies expires, disable sites except for public sites
    // If not, update global value
    if (cookies.get("accessToken") === undefined) {
      const isLogin = false;
      setUser({ isLogin });
      if (!publicSites.includes(currentURL)) {
        redirectPath("/");
      }
    } else {
      const accessToken = cookies.get("accessToken");
      const firstName = cookies.get("firstName");
      const lastName = cookies.get("lastName");
      const email = cookies.get("email");
      const phoneNumber = cookies.get("phoneNumber");
      const organizationId = cookies.get("organizationId");
      const title = cookies.get("title");
      const accountId = cookies.get("accountId");
      const organizationUserId = cookies.get("organizationUserId");
      const organizationName = cookies.get("organizationName");
      const isLogin = true;
      setUser({
        firstName,
        lastName,
        email,
        phoneNumber,
        organizationId,
        title,
        accessToken,
        accountId,
        organizationUserId,
        organizationName,
        isLogin,
      });
    }
  }, [location.pathname]);

  return (
    <GlobalContext.Provider value={globals}>{children}</GlobalContext.Provider>
  );
}
