// import {Box, Button, TextField, Typography} from "@mui/material";
// import React, { useState, useContext } from "react";
// import { GlobalContext } from "../utils/GlobalContext";
// import { useNavigate } from "react-router-dom";
// import "../CSS/Login.css"; // Assuming the same CSS can be applied
// import LogoStack from "../Assets/logo_stack.png";
//
// function AdminLogin() {
//     const [email, setEmail] = useState("");
//     const [password, setPassword] = useState("");
//     const [loginError, setLoginError] = useState(false);
//     const [loginHelperText, setLoginHelperText] = useState("");
//     const { setUser } = useContext(GlobalContext);
//     let redirectPath = useNavigate();
//
//     const handleAdminLogIn = async (e) => {
//         e.preventDefault();
//         if (email !== "admin@bilaunchpad.com" || password !== "bilpassword") {
//             setLoginError(true);
//             setLoginHelperText("Invalid credentials. Please try again.");
//             return;
//         }
//
//
//         const fakeResponse = {
//             firstName: "Admin",
//             lastName: "User",
//             email: "admin@bilaunchpad.com",
//             accessToken: "admin_access_token",
//             isLogin: true,
//         };
//
//         setUser(fakeResponse);
//
//
//         sessionStorage.setItem('isAdminLoggedIn', 'True');
//
//         redirectPath("/adminconsole");
//     };
//
//     const adminLoginForm = (
//         <Box className="login-panel flex-column" borderRadius={4}>
//             <Box component="img" sx={{ height: "100px" }} src={LogoStack} />
//
//             <Typography variant="h2" style={{ color: 'crimson' }}>Admin Console</Typography>
//
//
//             <Box className="login-fields">
//                 <TextField
//                     required
//                     fullWidth
//                     color="secondary"
//                     margin="dense"
//                     label="Email"
//                     variant="outlined"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                 />
//                 <TextField
//                     required
//                     fullWidth
//                     color="secondary"
//                     margin="dense"
//                     label="Password"
//                     variant="outlined"
//                     type="password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                 />
//                 <Button
//                     fullWidth
//                     size="large"
//                     variant="contained"
//                     sx={{ height: "56px", mt: "30px" }}
//                     onClick={handleAdminLogIn}
//                 >
//                     ADMIN LOGIN
//                 </Button>
//             </Box>
//         </Box>
//     );
//
//     return <>{adminLoginForm}</>;
// }
//
// export default AdminLogin;




import {Box, Button, TextField, Typography} from "@mui/material";
import React, { useState, useContext } from "react";
import { GlobalContext } from "../utils/GlobalContext";
import { useNavigate } from "react-router-dom";
import "../CSS/Login.css";
import LogoStack from "../Assets/logo_stack.png";

function AdminLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginStatusMessage, setLoginStatusMessage] = useState(""); // Renamed from loginError
    const { setUser } = useContext(GlobalContext);
    let navigate = useNavigate(); // Correct use of useNavigate hook

    const handleAdminLogIn = async (e) => {
        e.preventDefault();
        if (email !== "admin@bilaunchpad.com" || password !== "bilpassword") {
            setLoginStatusMessage("Wrong Credentials!"); // Set error message
            return;
        }

        const fakeResponse = {
            firstName: "Admin",
            lastName: "User",
            email: "admin@bilaunchpad.com",
            accessToken: "admin_access_token",
            isLogin: true,
        };

        setUser(fakeResponse);
        sessionStorage.setItem('isAdminLoggedIn', 'True');

        setLoginStatusMessage("Admin Login Successful!"); // Set success message

        // Delay the redirection to allow the success message to be read
        setTimeout(() => navigate("/adminconsole"), 2000); // Adjust the delay as needed
    };

    const adminLoginForm = (
        <Box className="login-panel flex-column" borderRadius={4}>
            <Box component="img" sx={{ height: "100px" }} src={LogoStack} />
            <Typography variant="h2" style={{ color: 'crimson' }}>Admin Console</Typography>
            <Typography variant="body1" style={{ color: loginStatusMessage.includes("Successful") ? 'green' : 'red' }}>
                {loginStatusMessage}
            </Typography>
            <Box className="login-fields">
                <TextField
                    required
                    fullWidth
                    color="secondary"
                    margin="dense"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    required
                    fullWidth
                    color="secondary"
                    margin="dense"
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    sx={{ height: "56px", mt: "30px" }}
                    onClick={handleAdminLogIn}
                >
                    ADMIN LOGIN
                </Button>
            </Box>
        </Box>
    );

    return <>{adminLoginForm}</>;
}

export default AdminLogin;
