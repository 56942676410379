import "../CSS/Layout.css";
import "../CSS/Footer.css";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import { GlobalContext } from "../utils/GlobalContext";

export default function Footer() {
  let redirectPath = useNavigate();
  const { isMobile } = useContext(GlobalContext);

  return (
    <Box className="footer-section">
      <Box className="foot-content">
        <Box className="foot-text-container">
          <a href="https://bac.umd.edu" target="_blank">
            <Box className="bac-logo" component="img" src="/bacLogo.png" />
          </a>
          <Typography className="foot-text" mt="10px" pl="62px">
            In collaboration with Federal Highway Administration to empower
            public and private organizations to deliver crucial transportation
            infrastructure projects using innovative financing and alternative
            delivery methods
          </Typography>
        </Box>

        {!isMobile && (
          <Box className="page-links">
            <Link href="/nofo" underline="hover" color="white" fontSize={14}>
              Funding Opportunities
            </Link>
            <Link
              href="/planahead"
              underline="hover"
              color="white"
              fontSize={14}
            >
              Plan Ahead
            </Link>
            <Link
              href="/successfulawards"
              underline="hover"
              color="white"
              fontSize={14}
            >
              Successful Awards
            </Link>
            <Link
              href="/boardmessages"
              underline="hover"
              color="white"
              fontSize={14}
            >
              Community Forum
            </Link>
            <Link href="/gethelp" underline="hover" color="white" fontSize={14}>
              Get Help
            </Link>
            <Link
              href="/disclaimer"
              underline="hover"
              color="white"
              fontSize={14}
            >
              Disclaimer
            </Link>
          </Box>
        )}

        <Box className="flex-column">
          <Typography fontSize={13} mb="30px" mt="32px">
            A Product of the Build America Center
          </Typography>
          <Box className="flex-row" sx={{ gap: "30px" }}>
            <a
              href="https://www.linkedin.com/company/build-america-center/?viewAsMember=true"
              target="_blank"
            >
              <Box
                className="external-logo"
                component="img"
                src="/linkedin.png"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC9BsbWS-SxrDYgBN0qFM-tg/about"
              target="_blank"
            >
              <Box
                className="external-logo"
                component="img"
                src="/youtube.png"
              />
            </a>
          </Box>
          <Typography fontSize={13} mt="30px">
            © 2022-2022 Build America Center. All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
