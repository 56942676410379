import { Box, CircularProgress } from "@mui/material";
import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { GlobalWrapper } from "./utils/GlobalContext";
import "./CSS/Layout.css";
import Landing from "./Pages/Landing";
import GetHelp from "./Pages/GetHelp";
import ReactGA from "react-ga4";
import AdminLogin from "./Pages/AdminLogin";

const Login = lazy(() => import("./Pages/Login"));
const Signup = lazy(() => import("./Pages/Signup"));
const NOFO = lazy(() => import("./Pages/NOFO"));
const Account = lazy(() => import("./Pages/Account"));
const Marketplace = lazy(() => import("./Pages/Marketplace"));
const Requests = lazy(() => import("./Pages/Requests"));
const BoardMessages = lazy(() => import("./Pages/BoardMessages"));
const AdminUtils = lazy(() => import("./Pages/AdminUtils"));
const Organization = lazy(() => import("./Pages/Organization"));
const Forgetpassword = lazy(() => import("./Pages/Forgetpassword"));
const Resetpassword = lazy(() => import("./Pages/Resetpassword"));
const PlanAhead = lazy(() => import("./Pages/PlanAhead"));
const NOFODetail = lazy(() => import("./Pages/NOFODetail"));
const Projects = lazy(() => import("./Pages/Projects"));
const Users = lazy(() => import("./Pages/Users"));
const CheckSimilarity = lazy(() => import("./Pages/CheckSimilarity"));
const ProjectDetail = lazy(() => import("./Pages/ProjectDetail"));
const SuccessfulAwards = lazy(() => import("./Pages/SuccessfulAwards"));
const Disclaimer = lazy(() => import("./Pages/Disclaimer"));
const Appointment = lazy(() => import("./Pages/Appointment"));
const StateDashboard =  lazy(() => import("./Pages/StateDashboard"));
function App() {
  useEffect(() => {
    ReactGA.initialize("G-TTNKB0GVVR");
  }, []);

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <Box className="display-center">
            <CircularProgress sx={{ margin: "auto" }} />
          </Box>
        }
      >
        <GlobalWrapper>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/" element={<Landing />} />
            <Route path="/nofo" element={<NOFO />} />
            <Route path="/nofodetail/:nofoid" element={<NOFODetail />} />
            <Route path="/planahead" element={<PlanAhead />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/boardmessages" element={<BoardMessages />} />
            <Route path="/adminconsole" element={<AdminUtils />} />
            <Route path="/adminlogin" element={<AdminLogin />} />
            <Route path="/account" element={<Account />} />
            <Route path="/organization" element={<Organization />} />
            <Route path="/forgetpassword" element={<Forgetpassword />} />
            <Route path="/resetpassword/:token" element={<Resetpassword />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/users" element={<Users />} />
            <Route
              path="/checksimilarity/:projectid"
              element={<CheckSimilarity />}
            />
            <Route
              path="projectdetail/:projectid"
              element={<ProjectDetail />}
            />
            <Route path="/successfulawards" element={<SuccessfulAwards />} />
            <Route path="/gethelp" element={<GetHelp />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/statedashboard" element={<StateDashboard />} />
          </Routes>
        </GlobalWrapper>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
